<!--短信渠道-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo">
                <el-form-item label="通道编码">
                    <el-input
                            v-model="filterInfo.smsChannelCode"
                            placeholder="通道编码"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="通道名称">
                    <el-input
                            v-model="filterInfo.smsChannelName"
                            placeholder="通道名称"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="toAdd()">添加短信渠道</el-button>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                <el-table-column prop="smsChannelCode" label="通道编码" width="120"/>
                <el-table-column prop="smsChannelName" label="通道名称" width="130"/>
                <el-table-column prop="isChannelTemplate" label="短信通道方注册模板" width="150">
                    <template slot="header">
                        <span>短信通道方注册模板</span>
                        <el-tooltip class="item" effect="dark" placement="top">
                            <div slot="content" >说明： 是否为短信通道方注册模板（传递变量值和模板code即可）
                            </div>
                            <i class="el-icon-warning" style="color: #E6A23C;font-size: 14px;margin-left: 5px;"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="{row}">
                        <span v-if="row.status">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="smsTypeCode" label="短信类型编码" width="140"/>
                <el-table-column prop="smsTypeName" label="短信类型名称" show-overflow-tooltip width="220"/>
                <el-table-column prop="smsSign" label=" 短信签名" width="120"/>
                <el-table-column prop="bizConfig" label="通道配置" >
                    <template slot-scope="{row}">
                        <el-tooltip placement="top">
                            <pre slot="content" style="max-width: 600px;" v-if="typeJson(row.bizConfig)">{{JSON.stringify(JSON.parse(row.bizConfig), null, '  ')}}</pre>
                            <pre slot="content"  style="max-width: 600px;" v-else>{{row.bizConfig}}</pre>
                            <div style="width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{row.bizConfig}}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="country" label="状态" fixed="right" width="100">
                    <template slot-scope="{ row }">
                        <el-switch
                                v-model="row.status"
                                :active-value="true"
                                :inactive-value="false"
                                disabled
                        />
                        <el-link
                                :underline="false"
                                :type="row.status ? 'primary' : ''"
                                class="status-label"
                        >&nbsp;&nbsp;{{
                            row.status === true ? "启用" : "禁用"
                            }}
                        </el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" width="145" fixed="right">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            编辑
                        </el-button>
                        <el-button size="small" type="danger" @click="del(row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getSmsChannel,delSmsChannel
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat,typeJson} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo:{
                    smsChannelCode:'',
                    smsChannelName:''
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            typeJson,

            toAdd(){
                this.$router.push({path:'/smsChannelDetail'})
            },

            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    }).then(() => {
                        delSmsChannel({
                            id: row.id,
                        }).then(() => {
                            this.$message.success("删除成功");
                            this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path:'/smsChannelDetail',query:{id:row.id}})
            },



            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getSmsChannel({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
