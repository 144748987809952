<template>
    <div class="global-view-card" v-loading="pageLoad">
        <!--<div class="global-detail-head">-->
        <!--{{ title }}-->
        <!--</div>-->
        <div class="global-detail-content">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="120px"
                    label-suffix=":"
            >
                <el-form-item label="公司名称" prop="companyName">
                    <el-input
                            v-model="detailForm.companyName"
                            placeholder="请输入公司名称"
                    />
                </el-form-item>
                <el-form-item label="营业执照号" prop="licenseNo">
                    <el-input
                            v-model="detailForm.licenseNo"
                            placeholder="请输入营业执照号"
                    />
                </el-form-item>
                <el-form-item label="担保公司" prop="guaranteeCompany">
                    <el-input
                            v-model="detailForm.guaranteeCompany"
                            placeholder="请输入担保公司"
                    />
                </el-form-item>
                <el-form-item label="产品名称" prop="productName">
                    <el-input
                            v-model="detailForm.productName"
                            placeholder="请输入产品名称"
                    />
                </el-form-item>
                <el-form-item label="产品编码" prop="productCode">
                    <el-input
                            v-model="detailForm.productCode"
                            placeholder="请输入产品名称"
                    />
                </el-form-item>
                <el-form-item label="产品额度" prop="defaultAmount">
                    <el-input
                            v-model="detailForm.defaultAmount"
                            placeholder="请输入产品额度"
                            @input="limitThree('defaultAmount')"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">元</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="最小借款额度" prop="loanMinAmount">
                    <el-input
                            v-model="detailForm.loanMinAmount"
                            placeholder="请输入借款最小借款额度"
                            @input="limitThree('loanMinAmount')"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">元</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="步长" prop="stepSize">
                    <el-input
                            v-model="detailForm.stepSize"
                            type="number"
                            placeholder="请输入借款步长"
                            @input="limitThree('stepSize')"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">元</i>
                    </el-input>
                    <p style="color: #909399;font-size: 12px;">注：一次最少增加多少的借款，如500,600,700</p>
                </el-form-item>
                <el-form-item label="借款期限" prop="defaultPeriod">
                    <el-input
                            v-model="detailForm.defaultPeriod"
                            placeholder="请输入借款期限"
                            @input="limitThree('defaultPeriod')"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">月</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="借款年化" prop="defaultYearsRate">
                    <el-input
                            v-model="detailForm.defaultYearsRate"
                            placeholder="请输入借款年化"
                            maxlength="6"
                            @input="(e)=>{limitFourPoint(e,'defaultYearsRate')}"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="借款日息" prop="defaultDayRate">
                    <el-input
                            v-model="detailForm.defaultDayRate"
                            type="number"
                            placeholder="请输入借款日息"
                            @input="(e)=>{limitFourPoint(e,'defaultDayRate')}"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%/日</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="借款最高年化" prop="loanTopAnnualized">
                    <el-input
                            v-model="detailForm.loanTopAnnualized"
                            type="number"
                            placeholder="请输入借款最高年化"
                            @input="(e)=>{limitFourPoint(e,'loanTopAnnualized')}"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">%</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="逾期罚息" prop="defaultOverdueMultiple">
                    <el-input
                            v-model="detailForm.defaultOverdueMultiple"
                            type="number"
                            placeholder="请输入逾期利率"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">倍</i>
                    </el-input>
                    <p style="color: #909399;font-size: 12px;">注：此处为日息倍数，默认逾期罚息为 日息1.5倍数</p>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="detailForm.status">
                        <el-option :value="1" label="启用"></el-option>
                        <el-option :value="0" label="禁用"></el-option>

                    </el-select>
                </el-form-item>

                <el-form-item class="global-detail-form-footer">
                    <!--<el-button @click="cancel">取 消</el-button>-->
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getProDetail, updateProduct
    } from "@/api/business";

    export default {

        data() {
            return {
                pageLoad: false,
                title: "",

                detailForm: {
                    id: null,
                    companyName: "",
                    licenseNo: "",
                    guaranteeCompany: "",
                    productCode: "",
                    productName: "1",
                    defaultAmount: null,
                    defaultPeriod: null,
                    defaultYearsRate: null,
                    defaultDayRate: "",
                    loanTopAnnualized: null,
                    loanMinAmount: null,
                    stepSize: null,
                    defaultOverdueMultiple: null,
                    status: 0
                },
                detailRules: {
                    companyName: [{required: true, message: "请输入公司名称"}],
                    licenseNo: [{required: true, message: "请输入营业执照号"}],
                    guaranteeCompany: [{required: true, message: "请输入担保公司"}],
                    productCode: [{required: true, message: "请输入产品编号"}],
                    productName: [{required: true, message: "请输入产品名称"}],
                    defaultAmount: [{required: true, message: "请输入借款额度"}],
                    defaultPeriod: [{required: true, message: "请输入借款期限"}],
                    defaultYearsRate: [{required: true, message: "请输入借款年化"}],
                    defaultDayRate: [{required: true, message: "请输入借款日息"}],
                    loanTopAnnualized: [{required: true, message: "请输入借款最高年化"}],
                    loanMinAmount: [{required: true, message: "请输入最小借款额度"}],
                    stepSize: [{required: true, message: "请输入步长"}],
                    defaultOverdueMultiple: [{required: true, message: "请输入逾期罚息"}],
                },
                saveLoad: false,
            };
        },
        created() {
            this.getProDetail();
        },
        methods: {
            limitThree(name) {//正整数且只能输入3位
                this.detailForm[name] = this.detailForm[name].replace(/^(0+)|[^\d]+/g, '')

            },
            limitFourPoint(e, type) {    //做多保留小数点后四位
                this.detailForm[type] = (e.match(/^\d*(\.?\d{0,4})/g)[0]) || null
            },

            //新增banner
            updateProduct(form) {
                this.saveLoad = true;
                updateProduct(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    // this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getProDetail() {
                this.pageLoad = true;
                getProDetail({})
                    .then((res) => {
                        Object.assign(this.detailForm, res.data);
                        this.pageLoad = false;
                    })
                    .catch(() => {
                        this.pageLoad = false;
                    });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        this.updateProduct(this.detailForm)
                    }
                });
            },
            cancel() {
                this.$router.push("/ProductList");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
