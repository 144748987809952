<template>
  <div class="global-view-card">
    <div class="global-detail-head">
      用户解密
    </div>
    <div class="global-detail-content" style="padding: 0 10px;">
      <el-form
          ref="decryptForm"
          :model="form"
          :rules="formRules"
          class="global-detail-content"
          label-width="auto"
          label-suffix=":"
      >
        <el-form-item label="" prop="content" required>

          <el-input
              type="textarea"
              :autosize="{minRows:6}"
              size="mini"
              style="width: 80%;"
              v-model="form.content"
              placeholder="请输入内容"
          ></el-input>

        </el-form-item>


        <el-form-item class="global-detail-form-footer">
          <el-button @click="cancel('decryptForm')">取 消</el-button>
          <el-button type="primary" :loading="saveLoad" @click="decryptUser('decryptForm')">解密</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  decryptUser
} from "@/api/business";


export default {
  data() {
    return {
      saveLoad: false,
      form: {
        content: ''
      },
      formRules: {
        content: [{required: true, message: "请输入内容"}],
      }
    };
  },
  created() {

  },
  methods: {
    decryptUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoad = true;
          decryptUser({content:this.form.content}).then(res => {
            this.$message.success('已解密，正在下载')
            this.downloadFile(res.data)
            this.saveLoad = false;
            this.cancel(formName)
          }).catch(() => {
            this.saveLoad = false;
          });
        }
      });
    },

    cancel(formName){
      this.$refs[formName].resetFields();
    },

    downloadFile(url){
      window.location.href=url
    }
  }
}
</script>

<style lang="scss">
.banner-detail-dialog {
  .el-dialog__body {
    padding: 16px;

    .el-pagination {
      text-align: right;
      margin-top: 16px;
    }
  }
}
</style>
