<!--系统资源列表-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo">
                <el-form-item label="服务商名称">
                    <el-input
                            v-model="filterInfo.thirdPartyName"
                            placeholder="服务商名称"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="服务商CODE">
                    <el-input
                            v-model="filterInfo.thirdPartyCode"
                            placeholder="服务商CODE"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="toAdd()">添加三方资源配置</el-button>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                <el-table-column prop="thirdPartyName" label="服务商名称" width="140" show-overflow-tooltip/>
                <el-table-column prop="thirdPartyCode" label="服务商CODE" width="150"/>
                <el-table-column prop="des" label="描述" width="200" show-overflow-tooltip/>
                <el-table-column prop="systemEnvironment" label="环境">
                    <template slot-scope="{row}">
                        <span v-if="row.systemEnvironment == 'dev'">开发</span>
                        <span v-if="row.systemEnvironment == 'test'">测试</span>
                        <span v-if="row.systemEnvironment == 'prod'">生产</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="调用方式" width="80">
                    <template slot-scope="{row}">
                        <span v-if="row.type == 'ASYNC'">异步</span>
                        <span v-if="row.type == 'SYNC'">同步</span>
                    </template>
                </el-table-column>
                <el-table-column prop="apiHost" label="域名" width="180" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span class="copyText" v-copyVal="row.apiHost">
                           <i class="el-icon-document-copy" style="margin-right: 5px;" v-if="row.apiHost"></i> {{row.apiHost}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="accessKey" label="访问密钥" width="200" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span class="copyText" v-copyVal="row.accessKey">
                           <i class="el-icon-document-copy"  style="margin-right: 5px;" v-if="row.accessKey"></i> {{row.accessKey}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="accessSecret" label="secret" width="200" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span class="copyText" v-copyVal="row.accessSecret">
                           <i class="el-icon-document-copy"  style="margin-right: 5px;" v-if="row.accessSecret"></i> {{row.accessSecret}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="json" label="具体数据配置" width="200">
                    <template slot-scope="{row}">
                        <el-tooltip placement="top">
                            <pre slot="content" style="max-width: 600px;" v-if="typeJson(row.json)">{{JSON.stringify(JSON.parse(row.json), null, '  ')}}</pre>
                            <pre slot="content"  style="max-width: 600px;" v-else>{{row.json}}</pre>
                            <div class="copyText overflowText" v-copy-val="row.json">
                                <i class="el-icon-document-copy"  style="margin-right: 5px;" v-if="row.json"></i>  {{row.json}}
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="jsonKeyDes" label="json key说明" width="200">
                    <template slot-scope="{row}">
                        <el-tooltip placement="top">
                            <pre slot="content" style="max-width: 600px;" v-if="typeJson(row.jsonKeyDes)">{{JSON.stringify(JSON.parse(row.jsonKeyDes), null, '  ')}}</pre>
                            <pre slot="content"  style="max-width: 600px;" v-else>{{row.jsonKeyDes}}</pre>
                            <div class="copyText overflowText" v-copy-val="row.jsonKeyDes">
                                <i class="el-icon-document-copy"  style="margin-right: 5px;" v-if="row.jsonKeyDes"></i> {{row.jsonKeyDes}}
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="addTime" label="创建时间" width="145"/>
                <el-table-column prop="addOperatorName" label="创建者姓名" width="100"/>
                <el-table-column prop="operation" label="操作" width="145">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            编辑
                        </el-button>
                        <el-button size="small" type="danger" @click="del(row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getThirdConfigPage, delThirdConfig
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat,typeJson} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo: {
                    thirdPartyName: '',
                    thirdPartyCode: ''
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            typeJson,
            toAdd() {
                this.$router.push({path: '/thirdResDetail'})
            },

            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    }).then(() => {
                    delThirdConfig({
                        id: row.id,
                    }).then(() => {
                        this.$message.success("删除成功");
                        this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                    });
                })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path: '/thirdResDetail', query: {id: row.id}})
            },


            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getThirdConfigPage({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .overflowText {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .copyText {
        cursor: pointer;
        color: #409EFF;
        text-decoration: underline;
    }

    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
