<!--定时任务日志-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo">
                <el-form-item label="任务组">
                    <el-input
                            v-model="filterInfo.jobGroup"
                            placeholder="任务组"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="任务Bean">
                    <el-input
                            v-model="filterInfo.jobBean"
                            placeholder="任务Bean"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="任务名称">
                    <el-input
                            v-model="filterInfo.jobName"
                            placeholder="任务名称"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="global-view-table">
            <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                <el-table-column prop="jobGroup" label="任务组" width="100"/>
                <el-table-column prop="jobBean" label="任务Bean" width="200" show-overflow-tooltip/>
                <el-table-column prop="jobName" label="任务名称" width="200" show-overflow-tooltip/>
                <el-table-column prop="jobCron" label="Cron表达式" width="145"/>
                <el-table-column prop="executeIp" label="可执行ip地址, JSONArray格式" width="220"/>
                <el-table-column prop="dataVersion" label="数据版本" width="100"/>
                <el-table-column prop="startTime" label="开始执行时间" width="145"/>
                <el-table-column prop="endTime" label="结束时间" width="145"/>
                <el-table-column prop="addTime" label="添加时间" width="145"/>
                <el-table-column prop="runTime" label="耗时(毫秒)" width="100"/>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getJobLog
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo:{
                    jobGroup:'',
                    jobBean:'',
                    jobName:''
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            changePage(page) {
                this.search(page);
            },
            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getJobLog({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
