<!--财务管理-->
<template>
  <div class="account-model">
    <!--    <div class="global-header">-->
    <!--      <el-form inline :model="filterInfo" label-width="auto">-->
    <!--        <el-form-item label="日期时间">-->
    <!--          <DatePicker-->
    <!--              v-model="filterInfo.time"-->
    <!--              type="daterange"-->
    <!--              range-separator="至"-->
    <!--              start-placeholder="开始日期"-->
    <!--              end-placeholder="结束日期"-->
    <!--              format="yyyy-MM-dd"-->
    <!--              value-format="yyyy-MM-dd"-->
    <!--              @change="changeTime"-->
    <!--          />-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="用户ID">-->
    <!--          <el-input v-model="filterInfo.userId" placeholder="请输入用户ID" clearable/>-->

    <!--        </el-form-item>-->
    <!--        <el-form-item label="手机号">-->
    <!--          <el-input v-model="filterInfo.mobile" placeholder="请输入手机号" clearable/>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="借款编号">-->
    <!--          <el-input v-model="filterInfo.loanOrderNo" placeholder="请输入借款编号" clearable/>-->

    <!--        </el-form-item>-->
    <!--        <el-form-item label="">-->
    <!--          <el-button-->
    <!--              :loading="tableLoad"-->
    <!--              type="primary"-->
    <!--              icon="el-icon-search"-->
    <!--              @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"-->
    <!--          >搜 索-->
    <!--          </el-button-->
    <!--          >-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--    </div>-->

    <div class="global-view-table">
      <el-button type="primary" style="margin-bottom: 10px;" @click="toAdd()">发起平账</el-button>
      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column prop="id" label="ID" width="60px"/>
        <el-table-column prop="loanOrderNo" label="借款编号" width="200px"/>
        <el-table-column prop="planOrderNo" label="还款计划订单号" width="260px"/>
        <el-table-column prop="upsChannelCode" label="支付平台" width="100px">
          <template slot-scope="scope">
            <span v-if="scope.row.upsChannelCode == 'Alipay'">支付宝</span>
            <span v-if="scope.row.upsChannelCode == 'WeChat'">微信</span>
            <span v-if="scope.row.upsChannelCode == 'UnionPay'">银联</span>
          </template>
        </el-table-column>
        <el-table-column prop="repayTotalAmount" label="平账总金额"/>
        <el-table-column prop="tradTime" label="用户支付成功时间" width="145px"/>
        <el-table-column prop="addTime" label="平账时间" width="145px"/>
        <el-table-column prop="operation" label="操作" width="80">
          <template slot-scope="{ row }">
            <el-button type="primary" size="small" @click="toDetail(row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <GPagination
          :total="total"
          :current-page="pageInfo.pageNum"
          :size="pageInfo.pageSize"
          @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import {getOrderEvenList} from "@/api/business";
import {filterFormat} from "@/utils/tools";
import {DatePicker} from "element-ui";

import GPagination from "@/components/GPagination";
import axios from 'axios'

export default {
  name: "account-list",
  components: {
    GPagination,
    DatePicker
  },
  data() {
    return {
      // filterInfo: {
      //   userId: null,
      //   startDate: '',
      //   endDate: '',
      //   mobile: '',
      //   loanOrderNo: '',
      //   time: []
      // },
      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
    };
  },
  created() {

    this.search(this.pageInfo);
  },
  methods: {
    toAdd(){
      this.$router.push({path:'/createBill'})
    },

    toDetail(row) {
      this.$router.push({path:'/createBill',query:{id:row.id}})
    },

    changePage(page) {
      this.search(page);
    },


    changeTime(time) {
      if (time && time.length) {
        this.filterInfo.startDate = time[0];
        this.filterInfo.endDate = time[1];
      } else {
        this.filterInfo.startDate = "";
        this.filterInfo.endDate = "";
      }
    },

    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getOrderEvenList({
        ...this.pageInfo,
        // ...filterFormat(this.filterInfo),
      }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.tableLoad = false;
      }).catch(() => {
        this.tableLoad = false;
      });
    }
  },
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;

  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }

  .role-tag {
    margin-right: 8px;
  }
}

.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}

.diy_card {
  min-width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  //box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>
