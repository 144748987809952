<!--短信模板-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo">
                <el-form-item label="发件人ID">
                    <el-input
                            v-model="filterInfo.senderId"
                            placeholder="发件人ID"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="平台固定短信模板编码">
                    <el-input
                            v-model="filterInfo.fixedTemplateCode"
                            placeholder="平台固定短信模板编码"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="toAdd()">添加短信模板</el-button>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                <el-table-column prop="senderId" label="发件人ID" width="100px" sortable/>
                <el-table-column prop="fixedTemplateCode" label="平台固定短信模板编码" width="240"/>

                <el-table-column prop="smsTypeCode" label="短信类型编码" width="100"/>
                <el-table-column prop="templateCode" label="短信方模板类型编码" width="240"/>

                <el-table-column prop="templateContent" label="模板内容" show-overflow-tooltip width="200"/>
                <el-table-column prop="description" label="模板用途说明" show-overflow-tooltip width="200"/>
                <el-table-column prop="limitTime" label="短信次数限制/天" width="110">
                    <template slot-scope="{row}">
                        {{row.limitTime == 0?'不限制':row.limitTime}}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="145"/>
                <el-table-column prop="createOperatorName" label="创建者姓名" width="100"/>

                <el-table-column prop="modifyTime" label="最后更新时间" width="145"/>
                <el-table-column prop="modifyOperatorName" label="最后更新者姓名" width="145"/>


                <el-table-column prop="country" label="状态" fixed="right" width="100">
                    <template slot-scope="{ row }">
                        <el-switch
                                v-model="row.status"
                                :active-value="true"
                                :inactive-value="false"
                                disabled
                                @change="changeStatus(row)"
                        />
                        <el-link
                                :underline="false"
                                :type="row.status ? 'primary' : ''"
                                class="status-label"
                        >&nbsp;&nbsp;{{
                            row.status === true ? "启用" : "禁用"
                            }}
                        </el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" width="145" fixed="right">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            编辑
                        </el-button>
                        <el-button size="small" type="danger" @click="del(row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getSmsTemplateList,editSmsTemplate,delSmsTemplate
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo:{
                    senderId:null,
                    fixedTemplateCode:''
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            toAdd(){
              this.$router.push({path:'/smsTemplateDetail'})
            },

            changeStatus(row) {
                editSmsTemplate({
                    id: row.id,
                }).then(() => {
                    this.$message.success("修改成功");
                });
            },


            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        delSmsTemplate({
                            id: row.id,
                        }).then(() => {
                            this.$message.success("删除成功");
                            this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
               this.$router.push({path:'/smsTemplateDetail',query:{id:row.id}})
            },



            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getSmsTemplateList({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
