<!--订单列表-->
<template>
  <div class="account-model">
    <div class="global-header">
      <el-form inline :model="filterInfo">
        <el-form-item label="借款订单编号">
          <el-input
              v-model.trim="filterInfo.loanOrderNo"
              placeholder="借款订单编号"
              clearable
          />
        </el-form-item>
        <el-form-item label="打款订单号">
          <el-input
              v-model.trim="filterInfo.remitOrderNo"
              placeholder="打款订单号"
              clearable
          />
        </el-form-item>
        <el-form-item label="打款渠道">
          <el-input
              v-model.trim="filterInfo.upsChannelCode"
              placeholder="打款渠道"
              clearable
          />
        </el-form-item>
        <el-form-item label="支付通道流水号">
          <el-input
              v-model.trim="filterInfo.upsChannelOrderNo"
              placeholder="支付通道流水号"
              clearable
          />
        </el-form-item>
        <el-form-item label="打款金额">
          <el-input
              v-model.trim="filterInfo.amount"
              placeholder="打款金额"
              clearable
          />
        </el-form-item>
        <el-form-item label="">
          <el-button
              :loading="tableLoad"
              type="primary"
              icon="el-icon-search"
              @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
          >搜 索
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="global-view-table">
      <div class="table-header">
        <el-radio-group v-model="filterInfo.remitStatus" @change="changeTab">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="item.value" v-for="(item,i) in stateList" :key="i">{{ item.label }}
          </el-radio-button>
        </el-radio-group>
      </div>

      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column prop="remitOrderNo" label="打款订单号"/>
        <el-table-column prop="upsChannelCode" label="打款渠道"/>
        <el-table-column prop="upsChannelOrderNo" label="支付通道流水号"/>
        <el-table-column prop="loanOrderNo" label="借款订单号"/>
        <el-table-column prop="userId" label="用戶ID" width="80">
          <template slot-scope="{row}">
            <span style="cursor: pointer;color: #409EFF;" @click="toUser(row.userId)">{{ row.userId }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="打款金额" width="120"/>
        <el-table-column prop="remitStatus" label="支付状态" width="100">
          <template slot-scope="{row}">
            <el-tag type="primary" v-if="row.remitStatus == 0">打款中</el-tag>
            <el-tag type="success" v-if="row.remitStatus == 1">打款成功</el-tag>
            <el-tag type="danger" v-if="row.remitStatus == -1">打款失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="failReason" label=" 支付失败原因" show-overflow-tooltip/>
      </el-table>

      <GPagination
          :total="total"
          :current-page="pageInfo.pageNum"
          :size="pageInfo.pageSize"
          @change="changePage"
      />
    </div>


    <el-dialog title="失败原因" :visible.sync="dialogFormVisible" center="">
      <div style="max-height: 500px;overflow-y: auto;">
        <!--<el-card style="margin-bottom: 10px;" shadow="never">-->
        <el-descriptions class="margin-top" :column="1">
          <!--<el-descriptions-item label="打款编号">kooriookami</el-descriptions-item>-->
          <!--<el-descriptions-item label="打款时间">18100000000</el-descriptions-item>-->
          <el-descriptions-item label="失败原因">{{ failReason }}</el-descriptions-item>
        </el-descriptions>
        <!--</el-card>-->
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRemitPage
} from "@/api/business";
import {filterFormat} from "@/utils/tools";
import {DatePicker} from "element-ui";

import GPagination from "@/components/GPagination";

export default {
  name: "account-list",
  components: {
    GPagination,
    DatePicker
  },
  data() {
    return {
      channelSource: [],
      filterInfo: {
        loanOrderNo: "",
        remitOrderNo: "",
        upsChannelCode: "",
        remitStatus: null,
        amount: "",
        upsChannelOrderNo: ""
      },

      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      dialogFormVisible: false,
      failReason: null,
      stateList: [{
        label: '打款中',
        value: '0'
      }, {
        label: '打款成功',
        value: '1'
      }, {
        label: '打款失败',
        value: '-1'
      }]
    };
  },
  created() {
    this.search(this.pageInfo);
  },
  methods: {
    findState(val) {
      return this.stateList.find(item => item.value == val)
    },

    toUser(id) {
      this.$router.push({path: '/userDetail', query: {id: id}})
    },
    changeTab(val) {
      this.filterInfo.state = val;
      this.search({pageNum: 1, pageSize: this.pageInfo.pageSize})
    },
    changePage(page) {
      this.search(page);
    },
    toDetail(row) {
      this.$router.push({path: '/orderDetail', query: {id: row.id, loanOrderNo: row.loanOrderNo}})
    },

    changeTime(time) {
      if (time && time.length) {
        this.filterInfo.startDate = `${time[0]} 00:00`;
        this.filterInfo.endDate = `${time[1]} 23:59`;
      } else {
        this.filterInfo.startDate = "";
        this.filterInfo.endDate = "";
      }
    },

    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getRemitPage({
        ...this.pageInfo,
        ...filterFormat(this.filterInfo),
      })
          .then((res) => {
            const {total, records} = res.data;
            this.tableData = records;
            this.total = total;
            this.tableLoad = false;
          })
          .catch(() => {
            this.tableLoad = false;
          });
    }
  },
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;

  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }

  .role-tag {
    margin-right: 8px;
  }
}

.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}
</style>
