<template>
    <div class="no_data">
        <img src="../../assets/img/no_data.png" alt="">
        <p>{{note}}</p>
    </div>
</template>

<script>
    export default {
        name: "index",
        props:{
            note:{
                type:String,
                default(){
                    return '暂无数据'
                }
            }

        }
    }
</script>

<style scoped lang="scss">
.no_data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 500px;

    img{
        width: 163px;
        height: 102px;
        margin-bottom: 20px;
    }
}
</style>
