<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">用户详情</div>
        <div class="global-detail-content" style="padding-left: 24px;">

            <section>
                <div class="sub_title">基本信息</div>
                <el-table border :data="basicData" size="mini">
                    <el-table-column prop="userName" label="用户名" width="100"/>
                    <el-table-column prop="mobile" label="手机号" width="100"/>
                    <el-table-column prop="idCardNo" label="身份证号" width="180"/>
                    <el-table-column prop="education" label="学历" width="100">
                        <template slot-scope="{row}">
                            <span>{{findEducation(row.education)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="provinceName" label="省市区" width="180">
                        <template slot-scope="{row}">
                            <span v-if="row.provinceName">{{row.provinceName}}</span>
                            <span v-if="row.cityName">{{row.cityName}}</span>
                            <span v-if="row.areaName">{{row.areaName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deatailAddress" label="详细地址"/>
                </el-table>
            </section>

            <section>
                <div class="sub_title">紧急联系人</div>
                <el-table border :data="contactList" size="mini">
                    <el-table-column prop="kind" label="类别"/>
                    <el-table-column prop="name" label="姓名"/>
                    <el-table-column prop="mobile" label="手机号"/>
                </el-table>
            </section>

            <section>
                <div class="sub_title">身份证信息</div>
                <el-table border :data="basicData" size="mini">
                    <el-table-column prop="userName" label="姓名" width="120"/>
                    <el-table-column prop="gender" label="性别" width="80">
                        <template slot-scope="{row}">
                            {{row.gender == 'M'?'男':'女'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="idCardNo" label="身份证号" width="160"/>
                    <el-table-column prop="idCardAddress" label="身份证地址"/>
                    <el-table-column prop="nation" label="民族" width="80"/>
                    <el-table-column label="有效期" width="230">
                        <template slot-scope="{row}">
                            <span v-if="row.certSignDate">{{row.certSignDate}}</span>
                            <span v-if="row.certSignDate && row.certValiDate">~</span>
                            <span v-if="row.certValiDate">{{row.certValiDate}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </section>

            <section>
                <div class="sub_title">人脸&身份证</div>
                <div class="sub_content">
                    <div class="sub_item">
                        <div class="sub_label">人脸识别</div>
                        <div class="sub_val">
                            <el-image
                                    style="width: 100px; height: 90px;margin-left: 10px;"
                                    :src="authImg.livingPhoto"
                                    :preview-src-list="[authImg.livingPhoto]">
                            </el-image>
                        </div>
                    </div>
                    <div class="sub_item" style="width: 50%;">
                        <div class="sub_label">身份证</div>
                        <div class="sub_val">
                            <el-image
                                    style="width: 100px; height: 90px;margin-left: 10px;"
                                    :src="authImg.idCardX"
                                    :preview-src-list="[authImg.idCardX]">
                            </el-image>
                            <el-image
                                    style="width: 100px; height: 90px;margin-left: 10px;"
                                    :src="authImg.idCardY"
                                    :preview-src-list="[authImg.idCardY]">
                            </el-image>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="sub_title">银行卡列表</div>
                <el-table v-loading="pageLoad" border :data="userBankVOList" size="mini">
                    <el-table-column prop="id" label="ID" width="80"/>
                    <el-table-column prop="uniqueCode" label="绑卡唯一编号"/>
                    <el-table-column prop="bankName" label="银行名"/>
                    <el-table-column prop="bankCode" label="银行编码"/>
                    <el-table-column prop="bankCardNo" label="银行卡号" width="200"/>
                    <el-table-column prop="bindChannel" label="绑卡渠道"/>
                    <el-table-column prop="channelUniqueCode" label="绑卡渠道方唯一编码" width="200"/>
                    <el-table-column prop="channelProtocolNo" label="绑卡渠道协议号" width="260"/>
                    <el-table-column prop="bindFinishTime" label="完成绑卡时间" width="145"/>
                </el-table>
            </section>

        </div>
    </div>
</template>

<script>
    import {
        getUserDetail
    } from "@/api/business";
    import {findEducation} from '@/utils/tools'

    export default {
        data() {
            return {
                pageLoad: false,
                pageId: this.$route.query.id || null,
                basicData: [],
                tabVal: '借款记录',
                authImg: {
                    livingPhoto: '',
                    idCardX: '',
                    idCardY: ''
                },
                contactList: [],
                userBankVOList: [],
                bankLoad: false,


            };
        },
        created() {
            this.pageId && this.getUserDetail();
        },
        methods: {
            findEducation,
            getUserDetail() {
                this.pageLoad = true;
                getUserDetail({userId: this.pageId})
                    .then((res) => {
                        this.basicData = [res.data];
                        this.authImg = {
                            livingPhoto: res.data.livingPhoto,
                            idCardX: res.data.idCardX,
                            idCardY: res.data.idCardY
                        };
                        this.contactList = res.data.contactList || [];
                        this.userBankVOList = res.data.userBankVOList || [];

                        this.pageLoad = false;
                    }).catch(() => {
                    this.pageLoad = false;
                });
            },
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }

    .content_title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 10px;
    }

    .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin: 10px 0;
    }

    .sub_content {
        display: flex;
        align-items: center;
        border: 1px solid #EBEEF5;
        background-color: #FFF;

        .sub_item {
            display: flex;
            width: 25%;
        }

        .sub_label {
            height: 100px;
            padding: 0 10px;
            font-weight: 600;
            font-size: 12px;
            color: #909399;
            background: #f5f7fa;
            line-height: 100px;
        }

        .sub_val {
            height: 100px;
            display: flex;
            align-items: center;
        }
    }


</style>
