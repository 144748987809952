<!--用户列表-->
<template>
    <div class="account-model">
        <div class="global-header">
            <el-form inline :model="filterInfo" label-width="auto">
                <el-form-item label="用户ID">
                    <el-input
                            v-model.trim="filterInfo.userId"
                            placeholder="用户ID"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="客户号">
                    <el-input
                            v-model.trim="filterInfo.customerNo"
                            placeholder="客户号"
                            clearable
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-button
                            :loading="tableLoad"
                            type="primary"
                            icon="el-icon-search"
                            @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                    >搜 索</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="global-view-table">
            <!--<div class="table-header">-->
            <!--<el-button class="right-operation" type="primary" @click="addMerchant"-->
            <!--&gt;创建账号-->
            <!--</el-button-->
            <!--&gt;-->
            <!--</div>-->

            <el-table v-loading="tableLoad" size="mini" border :data="tableData">
                <el-table-column prop="id" label="用户ID" width="90px" sortable />
                <el-table-column prop="customerNo" label="客户号" width="300"/>
                <el-table-column prop="channelCode" label="渠道编码" width="120"/>
                <el-table-column prop="userName" label="用户名"/>
                <el-table-column prop="idCardNo" label="身份证"/>
                <el-table-column prop="mobile" label="手机号"/>
                <el-table-column prop="operation" label="操作" width="80px">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getUserPage,
    } from "@/api/business";
    // import {DatePicker} from "element-ui";

    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
            // DatePicker
        },
        data() {
            return {
                channelSource: [],
                filterInfo: {
                    userId:null,
                    customerNo: ''
                },

                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path: '/userDetail', query: {id: row.id}})
            },

            changeTime(time) {
                if (time && time.length) {
                    this.filterInfo.startDate = `${time[0]} 00:00`;
                    this.filterInfo.endDate = `${time[1]} 23:59`;
                } else {
                    this.filterInfo.startDate = "";
                    this.filterInfo.endDate = "";
                }
            },

            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getUserPage({
                    ...this.pageInfo,
                    ...this.filterInfo,
                }).then((res) => {
                    const {total, records} = res.data;
                    this.tableData = records;
                    this.total = total;
                    this.tableLoad = false;
                }).catch(() => {
                    this.tableLoad = false;
                });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
