<template>
  <div class="global-view-card">
    <div class="global-detail-head" style="display: flex;justify-content: space-between;align-items: center;">
      <span>查看明细</span>
      <div>
        <el-tag type="danger" v-if="accountCheck == 0">未对账</el-tag>
        <el-tag type="success" v-if="accountCheck == 1">已对账</el-tag>
        <el-button type="primary" style="margin-left: 10px;" v-if="accountCheck == 0" @click="doConfirm">对账无误</el-button>
      </div>
    </div>
    <div class="global-detail-content">
      <div class="diy_search">
        <el-form inline :model="filterInfo" label-width="auto">
          <el-form-item label="借款编号">
            <el-input v-model="filterInfo.loanOrderNo" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="三方流水号">
            <el-input v-model="filterInfo.upsChannelOrderNo" placeholder="请输入" clearable/>
          </el-form-item>
          <el-form-item label="">
            <el-button
                :loading="filterInfo.orderType == 0 ? tableLoad:tableLoad2"
                type="primary"
                icon="el-icon-search"
                @click="search()"
            >搜 索
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="diy_content">
        <div class="table-header">
          <div class="diy_date">
            <el-radio-group v-model="filterInfo.orderType" @change="changeTab">
              <el-radio-button :label="0">放款记录</el-radio-button>
              <el-radio-button :label="1">还款记录</el-radio-button>
            </el-radio-group>
            <div class="diy_h4">日期：{{ filterInfo.currentDateStr }}</div>
          </div>
          <el-button type="primary" v-loading="exportLoading"
                     @click="excelBuild()">导出明细
          </el-button>
        </div>

        <el-table v-loading="tableLoad" border :data="tableData" v-if="filterInfo.orderType == 0">
          <el-table-column prop="loanOrderNo" label="借款编号"/>
          <el-table-column prop="periods" label="借款期数"/>
          <el-table-column prop="userId" label="用户ID"/>
          <el-table-column prop="upsChannelOrderNo" label="支付系统订单号"/>
          <el-table-column prop="upsChannelCode" label="支付通道" />
          <el-table-column prop="amount" label="放款金额（元）"/>
          <el-table-column prop="successTime" label="交易时间"/>
        </el-table>

        <GPagination
            style="margin-top: 10px;"
            v-if="filterInfo.orderType == 0"
            :total="total"
            :current-page="pageInfo.pageNum"
            :size="pageInfo.pageSize"
            @change="changePage"
        />

        <el-table v-loading="tableLoad2" border :data="tableData2" v-if="filterInfo.orderType == 1">
          <el-table-column prop="loanOrderNo" label="借款编号" />
          <el-table-column prop="periods" label="借款期数" width="80"/>
          <el-table-column prop="userId" label="用户ID" width="80"/>
          <el-table-column prop="upsChannelOrderNo" label="支付系统订单号" />
          <el-table-column prop="upsChannelCode" label="支付通道" width="100" />
          <el-table-column prop="amount" label="还款金额（元）" width="120"/>
          <el-table-column prop="successTime" label="交易时间" width="200"/>
        </el-table>

        <GPagination
            style="margin-top: 10px;"
            v-if="filterInfo.orderType == 1"
            :total="total2"
            :current-page="pageInfo2.pageNum"
            :size="pageInfo2.pageSize"
            @change="changePage2"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {
  getOrderStatisticalDetail, doAccountCheck,excelBuild
} from "@/api/business";
import GPagination from "@/components/GPagination";
import {filterFormat} from "@/utils/tools";

export default {
  components: {
    GPagination,
  },
  data() {
    return {
      exportLoading: false,
      filterInfo: {
        orderType: 0,   //订单类型 0 打款 1 还款
        upsChannelOrderNo: null,
        loanOrderNo: null,
        currentDateStr: this.$route.query.currentDateStr || null,
      },
      accountCheck: this.$route.query.accountCheck || null,
      id: this.$route.query.id || null,
      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      tableLoad2: false,
      tableData2: [],
      total2: 0,
      pageInfo2: {
        pageNum: 1,
        pageSize: 20,
      },
      productList: [],
      channelSource: []
    };
  },
  created() {
    // this.getProductList();
    this.filterInfo.currentDateStr ? this.getOrderStatisticalDetail({
      pageNum: 1,
      pageSize: this.pageInfo.pageSize
    }) : ''
    // this.allChannelList()
  },
  methods: {
    excelBuild() {
      this.exportLoading = true;
      excelBuild({
          ...filterFormat(this.filterInfo),
        ...filterFormat(this.filterInfo.orderType === 0 ? this.pageInfo:this.pageInfo2)
      })
          .then((res) => {
              window.location.href = res.data
              this.exportLoading = false;
          })
          .catch(() => {
              this.exportLoading = false;
          });
    },
    doConfirm() {
      this.$messageBox.confirm('确认是否对账无误, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        doAccountCheck({id: this.id}).then(() => {
          this.id = null;
          this.accountCheck = 1;
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
        }).catch(() => {

        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    search() {
      if (this.filterInfo.orderType == 0) {
        this.getOrderStatisticalDetail({pageNum: 1, pageSize: this.pageInfo.pageSize});
      } else {
        this.getOrderStatisticalDetail({pageNum: 1, pageSize: this.pageInfo2.pageSize})
      }
    },

    // 获取枚举
    allChannelList() {
      // allChannelList({}).then((res) => {
      //   this.channelSource = res.data;
      // })
    },

    changeTab(val) {
      this.filterInfo.orderType = val;
      if (val == 0) {
        this.getOrderStatisticalDetail({pageNum: 1, pageSize: this.pageInfo.pageSize});
      } else {
        this.getOrderStatisticalDetail({pageNum: 1, pageSize: this.pageInfo2.pageSize})
      }
    },


    changePage(page) {
      this.getOrderStatisticalDetail(page);
    },
    changePage2(page) {
      this.getOrderStatisticalDetail(page);
    },

    getOrderStatisticalDetail(page) {
      let pageForm = null;
      if(this.filterInfo.orderType === 0){
        this.pageInfo = page;
        this.tableLoad = true;
      }else {
        this.pageInfo2 = page;
        this.tableLoad2 = true;
      }
      pageForm = page;
      getOrderStatisticalDetail({
        ...pageForm,
        ...filterFormat(this.filterInfo),
      })
          .then((res) => {
            if (this.filterInfo.orderType == 0) {
              this.tableData = res.data.loanOrderDetails.records || [];
              this.total = res.data.loanOrderDetails.total;
              this.tableLoad = false;
            } else {
              this.tableData2 = res.data.repayOrderDetails.records || [];
              this.total2 = res.data.repayOrderDetails.total;
              this.tableLoad2 = false;
            }

          })
          .catch(() => {
            if (this.filterInfo.orderType == 0) {
              this.tableLoad = false;
            } else {
              this.tableLoad2 = false;
            }
          });
    }
  }
};
</script>

<style lang="scss">
.banner-detail-dialog {
  .el-dialog__body {
    padding: 16px;

    .el-pagination {
      text-align: right;
      margin-top: 16px;
    }
  }
}

.diy_search {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 16px 0 16px 16px;

  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}

.diy_content {
  padding: 16px 0 16px 16px;
  width: 100%;

  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .diy_date {
    height: 32px;
    display: flex;
    align-items: end;

    .diy_h4 {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
    }
  }

}
</style>
