<template>
    <div class="global-view-card banner-model">
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="add"
                >添加产品
                </el-button
                >
            </div>

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="id" label="ID" width="80px"/>
                <el-table-column prop="productName" label="产品名称"/>
                <el-table-column prop="defaultAmount" label="最高额度"/>
                <el-table-column prop="defaultDayRate" label="日利率（%）"/>
                <el-table-column prop="defaultOverdueRate" label="逾期日利率（%）"/>
                <el-table-column prop="defaultOverduePunishRate" label="逾期费率（%）"/>
                <!--<el-table-column prop="productLink" label="产品链接"/>-->
                <el-table-column
                        prop="operation"
                        label="操作"
                        width="150px"
                        fixed="right"
                >
                    <template slot-scope="{ row }">
                        <el-button stype="warning" @click="edit(row)"> 编辑</el-button>
                        <el-button type="danger" @click="del(row)"> 删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {getProductList,deleteProduct} from "@/api/public";
    // import { filterFormat } from "@/utils/tools";
    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                filterInfo: {
                    roleName: null,
                },
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
            };
        },

        mounted() {
            this.search(this.pageInfo);
        },
        methods: {
            add() {
                this.$router.push("/productDetail");
            },
            edit(row) {
                this.$router.push(`productDetail?id=${row.id}`);
            },
            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getProductList({
                    ...this.pageInfo,
                    // ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            },
            changePage(page) {
                this.search(page);
            },

            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        deleteProduct({
                            id: row.id,
                        }).then(() => {
                            this.$message.success("删除成功");
                            this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
    };
</script>

<style lang="scss">
    .banner-model {
        .global-view-table {
            margin: 0;
        }
        .table-column-icon {
            width: 72px;
            height: 20px;
            border-radius: 4px;
            overflow: hidden;
        }
    }
</style>
