<!--系统资源列表-->
<template>
        <div class="account-model">
                <div class="global-header">
                        <el-form inline :model="filterInfo">
                                <el-form-item label="配置标识">
                                        <el-input
                                                v-model="filterInfo.configCode"
                                                placeholder="配置标识"
                                                clearable
                                        />
                                </el-form-item>
                                <el-form-item label="">
                                        <el-button
                                                :loading="tableLoad"
                                                type="primary"
                                                icon="el-icon-search"
                                                @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
                                        >搜 索</el-button>
                                </el-form-item>
                        </el-form>
                </div>
                <div class="global-view-table">
                        <div class="table-header">
                                <el-button class="right-operation" type="primary" @click="toAdd()">添加系统资源配置</el-button>
                        </div>

                        <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                                <el-table-column prop="configCode" label="配置标识" width="200"/>
                                <el-table-column prop="configValue" label="配置值">
                                        <template slot-scope="{row}">
                                                <el-tooltip placement="top">
                                                        <p slot="content" style="max-width: 600px;">{{row.configValue}}</p>
                                                        <div style="width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{row.configValue}}</div>
                                                </el-tooltip>
                                        </template>
                                </el-table-column>
                                <el-table-column prop="remark" label="配置描述" width="200" show-overflow-tooltip/>
                                <el-table-column prop="createTime" label="创建时间" width="145"/>
                                <el-table-column prop="modifyOperatorName" label="创建者姓名" width="100"/>
                                <el-table-column prop="modifyTime" label="最后修改时间" width="145"/>
                                <el-table-column prop="modifyOperatorName" label="最后修改者姓名" width="110"/>
                                <el-table-column prop="operation" label="操作" width="145">
                                        <template slot-scope="{ row }">
                                                <el-button size="small" @click="toDetail(row)">
                                                        编辑
                                                </el-button>
                                                <el-button size="small" type="danger" @click="del(row)">
                                                        删除
                                                </el-button>
                                        </template>
                                </el-table-column>
                        </el-table>

                        <GPagination
                                :total="total"
                                :current-page="pageInfo.pageNum"
                                :size="pageInfo.pageSize"
                                @change="changePage"
                        />
                </div>
        </div>
</template>

<script>
    import {
        getResConfigPage,delResConfig
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo:{
                    configCode:'',
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            toAdd(){
                this.$router.push({path:'/systemResDetail'})
            },

            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    }).then(() => {
                    delResConfig({
                        id: row.id,
                    }).then(() => {
                        this.$message.success("删除成功");
                        this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                    });
                })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path:'/systemResDetail',query:{id:row.id}})
            },



            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getResConfigPage({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
        .account-model {
                width: 100%;
                height: 100%;
                padding: 16px;
                overflow-y: auto;
                .global-view-table {
                        height: auto;
                        margin-top: 16px;
                        padding: 16px;
                }
                .role-tag {
                        margin-right: 8px;
                }
        }

        .account-dialog {
                .el-dialog__body {
                        padding: 24px 24px 0;
                }
        }
</style>
