<template>
  <div class="global-view-card" v-loading="pageLoad">
    <div class="global-detail-head">
      {{ title }}
      <span style="color: red;font-size: 12px;margin-left: 5px;">注意：所有还款如果有逾期，必须逾期结清后才能进行（当期或者全部还款）</span>
    </div>
    <div class="global-detail-content" style="padding: 0 10px;">
      <el-form
          ref="detailForm"
          :model="detailForm"
          :rules="detailRules"
          class="global-detail-content"
          label-width="auto"
          label-suffix=":"
      >
        <el-form-item label="借款订单编号" prop="loanOrderNo">
          <el-input
              v-model="detailForm.loanOrderNo"
              :disabled="isDetail"
          />
        </el-form-item>
        <el-form-item label="还款计划订单号" prop="planOrderNo">
          <el-input
              v-model="detailForm.planOrderNo"
              :disabled="isDetail"
          />
        </el-form-item>
        <el-form-item label="当前期数" prop="currentPeriod">
          <el-input
              v-model="detailForm.currentPeriod"
              :disabled="isDetail"
          />
        </el-form-item>
        <el-form-item label="还款类型" prop="repayType">
          <el-select v-model="detailForm.repayType" :disabled="isDetail">
            <el-option label="全部结清" :value="1"></el-option>
            <el-option label="正常还款" :value="2"></el-option>
            <el-option label="当期结清" :value="3"></el-option>
            <el-option label="逾期还款" :value="4"></el-option>
          </el-select>
          <span style="font-size: 11px;color: #999;"><i class="el-icon-warning" style="color: #E6A23C;"></i> 说明： 2.还款日正常还当期的为【正常还款】3.非还款日提前还当期的为【当期结清】4 逾期还款是按照当期全部金额做试算</span>
        </el-form-item>
        <el-form-item label="支付平台" prop="upsChannelCode">
          <el-select v-model="detailForm.upsChannelCode" :disabled="isDetail">
            <el-option label="支付宝" value="Alipay"></el-option>
            <el-option label="微信" value="WeChat"></el-option>
            <el-option label="银联" value="UnionPay"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="支付平台流水号" prop="upsChannelOrderNo">
          <el-input
              v-model="detailForm.upsChannelOrderNo"
              :disabled="isDetail"
          />
        </el-form-item>
        <el-form-item label="平账总金额" prop="repayTotalAmount">
          <el-input
              v-model="detailForm.repayTotalAmount"
              :disabled="isDetail"
              type="number"
          />
        </el-form-item>
        <el-form-item label="平账本金" prop="repayAmount">
          <el-input
              v-model="detailForm.repayAmount"
              :disabled="isDetail"
              type="number"
          />
        </el-form-item>

        <el-form-item label="平账的利息" prop="repayInterest">
          <el-input
              v-model="detailForm.repayInterest"
              :disabled="isDetail"
              type="number"
          />
        </el-form-item>
        <el-form-item label="用户支付交易截图" prop="screenshotUrl">
          <GUploadImg v-model="detailForm.screenshotUrl" :read-only="isDetail" :customSize="uploadSize"></GUploadImg>
        </el-form-item>
        <el-form-item label="用户支付成功时间" prop="tradTime">
          <el-date-picker
              v-model="detailForm.tradTime"
              :disabled="isDetail"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime">
          </el-date-picker>
          <span style="font-size: 11px;color: #999;margin-left: 10px;"><i class="el-icon-warning" style="color: #E6A23C;"></i> 说明： 只能平当天的还款</span>
        </el-form-item>
        <el-form-item label="平账逾期费">
          <el-input
              v-model="detailForm.repayOverdueAmount"
              :disabled="isDetail"
              type="number"
          />
        </el-form-item>
        <el-form-item label="平账服务费">
          <el-input
              v-model="detailForm.repayPoundage"
              :disabled="isDetail"
          />
        </el-form-item>
        <el-form-item label="平账手续费">
          <el-input
              v-model="detailForm.repayFee"
              :disabled="isDetail"
          />
        </el-form-item>

        <el-form-item class="global-detail-form-footer">
          <el-button @click="cancel">{{ isDetail ? '返 回':'取 消' }}</el-button>
          <el-button type="primary" :loading="saveLoad" @click="save" v-if="!isDetail">提 交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import GUploadImg from "@/components/GUploadImg/index.vue";
import {
  getOrderEvenDetail,addOrderEven,
} from "@/api/business";


export default {
  components:{
    GUploadImg
  },
  data() {
    return {
      pageLoad: false,
      title: "发起平账",
      isDetail:false,
      id: null,
      detailForm: {
        loanOrderNo: "",
        planOrderNo: "",
        currentPeriod: "",
        repayType: null,
        upsChannelCode: "",
        upsChannelOrderNo: "",
        repayTotalAmount: "",
        repayAmount: "",
        repayOverdueAmount:"",
        repayInterest: "",
        screenshotUrl: "",
        tradTime: "",
        repayPoundage: "",
        repayFee: ""
      },
      uploadSize:{
        width:'75',
        height:'133'
      },
      detailRules: {
        loanOrderNo: [{required: true, message: "请输入借款订单编号"}],
        planOrderNo:  [{required: true, message: "请输入还款计划订单号"}],
        currentPeriod:  [{required: true, message: "请输入当前期数"}],
        repayType:  [{required: true, message: "请选择还款类型", trigger: 'change'}],
        upsChannelCode:  [{required: true, message: "请选择支付平台", trigger: 'change'}],
        upsChannelOrderNo:  [{required: true, message: "请输入支付平台流水号"}],
        repayTotalAmount:  [{required: true, message: "请输入平账总金额"}],
        repayAmount:  [{required: true, message: "请输入平账本金"}],
        repayInterest:  [{required: true, message: "请输入平账的利息"}],
        screenshotUrl:  [{required: true, message: "请选择 用户支付交易截图", trigger: 'change'}],
        tradTime:  [{required: true, message: "请选择用户支付成功时间", trigger: 'change'}],
      },
      saveLoad: false,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.title = '平账详情';
      this.isDetail = true;
      this.getOrderEvenDetail();
    }
  },
  methods: {
    //新增模板
    addOrderEven(form) {
      this.saveLoad = true;
      addOrderEven(
          form
      ).then(() => {
        this.saveLoad = false;
        this.cancel()
        this.$message.success('提交成功')
      }).catch(() => {
        this.saveLoad = false;
      })
    },

    getOrderEvenDetail() {
      this.pageLoad = true;
      getOrderEvenDetail({
        id: this.$route.query.id
      }).then((res) => {
        Object.assign(this.detailForm, res.data);
        this.pageLoad = false;
      }).catch(() => {
        this.pageLoad = false;
      });
    },

    save() {
      this.$refs.detailForm.validate((valid) => {
        if (valid) {
          this.$messageBox.confirm('确认发起该笔平账吗, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.addOrderEven(this.detailForm)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });

        }
      });
    },
    cancel() {
      this.$router.push("/balanceBill");
    }
  },
};
</script>

<style lang="scss">
.banner-detail-dialog {
  .el-dialog__body {
    padding: 16px;
    .el-pagination {
      text-align: right;
      margin-top: 16px;
    }
  }
}
</style>
