<!--财务管理-->
<template>
  <div class="account-model">
    <div class="global-header">
      <el-form inline :model="filterInfo" label-width="auto">
        <!--                <el-form-item label="借款产品">-->
        <!--                    <el-select-->
        <!--                            v-model="filterInfo.productId"-->
        <!--                            placeholder="请选择借款产品"-->
        <!--                            clearable-->
        <!--                    >-->
        <!--                        <el-option label="全部" :value="null"/>-->
        <!--                        <el-option v-for="(item,i) in productList" :key="i" :label="item.productName" :value="item.id"/>-->

        <!--                    </el-select>-->
        <!--                </el-form-item>-->
        <el-form-item label="日期时间">
          <DatePicker
              v-model="filterInfo.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="changeTime"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button
              :loading="tableLoad"
              type="primary"
              icon="el-icon-search"
              @click="search({ pageNum: 1, pageSize: pageInfo.pageSize })"
          >搜 索
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="global-view-table">
      <p class="diy_h3">数据总览</p>
      <div class="table-header" v-loading="tableLoad">
        <el-card style="width: 40%;">
          <div class="diy_around" style="color: #409EFF;">
            <div class="diy_card">
              <p class="diy_h2">￥{{ dataForm.totalLoanAmount }}</p>
              <p class="diy_h2">业务放款总额</p>
            </div>
            <div class="diy_card">
              <p class="diy_h2">{{ dataForm.totalLoanCount }}</p>
              <p class="diy_h2">业务放款笔数</p>
            </div>
          </div>
        </el-card>
        <el-card style="width: 40%;margin-left: 20px;">
          <div class="diy_around" style="color: #F56C6C;">
            <div class="diy_card">
              <p class="diy_h2">￥{{ dataForm.totalRepaymentAmount }}</p>
              <p class="diy_h2">业务收款总额</p>
            </div>
            <div class="diy_card">
              <p class="diy_h2">{{ dataForm.totalRepaymentCount }}</p>
              <p class="diy_h2">业务收款笔数</p>
            </div>
          </div>
        </el-card>
      </div>

      <p class="diy_h3">对账任务</p>
      <el-table v-loading="tableLoad" border :data="tableData">
        <el-table-column prop="currentDateStr" label="日期" sortable />
        <el-table-column prop="remitTotalAmount" label="放款总额（元）"/>
        <el-table-column prop="remitCount" label="放款笔数"/>
        <el-table-column prop="repayTotalAmount" label="收款总额（元）"/>
        <el-table-column prop="repayCount" label="收款笔数"/>
        <el-table-column prop="accountCheck" label="对账状态">
          <template slot-scope="{row}">
            <el-tag type="success" v-if="row.accountCheck == 1">已对账</el-tag>
            <el-tag type="danger" v-if="row.accountCheck == 0">未对账</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="195">
          <template slot-scope="{ row }">
            <el-button size="small" @click="toDetail(row)">查看明细</el-button>
            <el-button size="small" v-if="row.accountCheck == 0" @click="doConfirm(row)" type="primary">对账无误</el-button>
          </template>
        </el-table-column>
      </el-table>

      <GPagination
          :total="total"
          :current-page="pageInfo.pageNum"
          :size="pageInfo.pageSize"
          @change="changePage"
      />
    </div>
  </div>
</template>

<script>
import {getOrderStatisticalPage, doAccountCheck} from "@/api/business";
import {filterFormat} from "@/utils/tools";
import {DatePicker} from "element-ui";

import GPagination from "@/components/GPagination";

export default {
  name: "account-list",
  components: {
    GPagination,
    DatePicker
  },
  data() {
    return {
      filterInfo: {
        productId: null,
        startDate: '',
        endDate: '',
        time: []
      },
      tableLoad: false,
      tableData: [],
      total: 0,
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      dataForm: {
        totalLoanAmount: 0,
        totalLoanCount: 0,
        totalRepaymentAmount: 0,
        totalRepaymentCount: 0,
      },
      productList: []
    };
  },
  created() {
    this.search(this.pageInfo);
  },
  methods: {
    doConfirm(row) {
      this.$messageBox.confirm('确认是否对账无误, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        doAccountCheck({id: row.id}).then(() => {
          this.search(this.pageInfo)
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
        }).catch(() => {

        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    changePage(page) {
      this.search(page);
    },
    toDetail(row) {
      this.$router.push({
        path: '/financialDetail',
        query: {id: row.id, currentDateStr:row.currentDateStr, accountCheck: row.accountCheck}
      })
    },

    changeTime(time) {
      if (time && time.length) {
        this.filterInfo.startDate = time[0];
        this.filterInfo.endDate = time[1];
      } else {
        this.filterInfo.startDate = "";
        this.filterInfo.endDate = "";
      }
    },

    search(page) {
      this.pageInfo = page;
      this.tableLoad = true;
      getOrderStatisticalPage({
        ...this.pageInfo,
        ...filterFormat(this.filterInfo),
      })
          .then((res) => {
            this.dataForm = {
              totalLoanAmount: res.data.totalLoanAmount,
              totalLoanCount: res.data.totalLoanCount,
              totalRepaymentAmount: res.data.totalRepaymentAmount,
              totalRepaymentCount: res.data.totalRepaymentCount,
            };
            this.tableData = res.data.reconciliationVOPageResponse.records;
            this.total = res.data.reconciliationVOPageResponse.total;
            this.tableLoad = false;
          })
          .catch(() => {
            this.tableLoad = false;
          });
    }
  },
};
</script>

<style lang="scss">
.account-model {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;

  .global-view-table {
    height: auto;
    margin-top: 16px;
    padding: 16px;
  }

  .role-tag {
    margin-right: 8px;
  }
}

.account-dialog {
  .el-dialog__body {
    padding: 24px 24px 0;
  }
}

.diy_card {
  min-width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  //box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>
