<template>
    <div class="global-view-card banner-model">
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="add">添加</el-button>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData">
                <el-table-column prop="id" label="ID" width="80px"/>
                <el-table-column prop="appName" label="关联应用" width="145"/>
                <el-table-column prop="channelCode" label="更新范围" width="120">
                    <template slot-scope="{row}">
                        {{row.beginVersion}} - {{row.endVersion}}
                    </template>
                </el-table-column>
                <el-table-column prop="finalVersion" label="版本号" width="100px"/>
                <el-table-column prop="upgradeDescription" label="升级描述" show-overflow-tooltip/>
                <el-table-column prop="updateType" label="更新类型" width="100px">
                    <template slot-scope="{row}">
                        <el-tag type="primary" v-if="row.updateType == 'NORMAL'">正常</el-tag>
                        <el-tag type="danger" v-if="row.updateType == 'FORCE'">强制</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="downloadUrl" label="下载地址" width="145">
                    <template slot-scope="{row}">
                        <span @click="copy(row.downloadUrl)" style="cursor: pointer;color: #409EFF;">{{row.downloadUrl}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="145" sortable/>
                <el-table-column prop="status" label="状态" width="120px">
                    <template slot-scope="{ row }">
                        <el-switch
                                v-model="row.status"
                                :active-value="1"
                                :inactive-value="0"
                                @change="changeStatus(row)"
                        />
                        <el-link
                                :underline="false"
                                :type="row.status === 1 ? 'primary' : ''"
                                class="status-label"
                        >&nbsp;&nbsp;{{
                            row.status === 1 ? "启用" : "禁用"
                            }}
                        </el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column
                        prop="operation"
                        label="操作"
                        width="145px"
                >
                    <template slot-scope="{ row }">
                        <!--<el-button type="primary" @click="copy(row)" v-if="row.channelUrl">复制链接</el-button>-->
                        <el-button @click="edit(row)"> 编辑</el-button>
                        <el-button type="danger" @click="del(row)"> 删除</el-button>

                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {getAppVersionConfigList,changeVersionConfigStatus,deleteAppVersionConfig} from "@/api/public";
    import GPagination from "@/components/GPagination";

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
            };
        },
        created() {

        },
        mounted() {
            this.search(this.pageInfo);
        },
        methods: {
            add() {
               this.$router.push({path:'/versionDetail'})
            },

            edit(row) {
                this.$router.push({path:'/versionDetail',query:{id:row.id}})
            },

            copy(url) {
                // 创建输入框元素
                let oInput = document.createElement('input');
                // 将想要复制的值
                oInput.value = url;
                // 页面底部追加输入框
                document.body.appendChild(oInput);
                // 选中输入框
                oInput.select();
                // 执行浏览器复制命令
                document.execCommand('Copy');
                // 弹出复制成功信息
                this.$message.success('复制成功');
                // 复制后移除输入框
                oInput.remove();
            },

            search(page) {
                this.pageInfo = page;
                this.tableLoad = true;
                getAppVersionConfigList({
                    ...this.pageInfo,
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            },
            changePage(page) {
                this.search(page);
            },
            changeStatus(row) {
                changeVersionConfigStatus({
                    id: row.id,
                    status: row.status,
                }).then(() => {
                    this.$message.success("修改成功");
                });
            },
            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    })
                    .then(() => {
                        deleteAppVersionConfig({
                            id: row.id,
                        }).then(() => {
                            this.$message.success("删除成功");
                            this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
        },
    };
</script>

<style lang="scss">
    .banner-model {
        .global-view-table {
            margin: 0;
        }
        .table-column-icon {
            width: 72px;
            height: 20px;
            border-radius: 4px;
            overflow: hidden;
        }
    }
</style>
